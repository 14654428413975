import React from "react";
import coral from "./coral.jpeg";
import pnecb from "./PNEC-B.png";
import "./introduccion.css";

export default () => (
  <section
    className="container"
    style={{ backgroundImage: `url(${coral})`, backgroundRepeat: "repeat" }}
  >
    <div className="seaflower">
      <div className="firstDiv">
        <img src={pnecb}></img>
      </div>
      <div className="secondDiv" style={{ justifyContent: "center" }}>
        {/* <h4>Expedición Científica Seaflower 2022 - Isla Cayos de Bolívar</h4> */}
        <p className="parrafo" style={{ color: "white" }}>
          El Plan Nacional de Expediciones Científicas Marinas (PNEC) es una
          estrategia gestada por la Comisión Colombiana del Océano (CCO) como
          una plataforma de investigación para el fortalecimiento de las
          capacidades científicas y técnicas de la nación. El objetivo de su
          implementación es fortalecer la generación de conocimiento científico
          integral, con miras a potencializar el desarrollo sostenible, la
          apropiación del territorio, el bienestar de la población local y el
          adecuado manejo de los recursos naturales en nuestros espacios marinos
          y costeros, tanto en la cuenca del Pacífico como en el mar Caribe.
          <br />
          <br />
          La Comisión Colombiana del Océano abre la convocatoria de proyectos de
          investigación para el desarrollo de la Expedición Científica Seaflower
          2022 - Isla Cayos de Bolívar y Expedición Científica Pacífico 2023 -
          Golfo de Tribugá, con el fin de consolidar un banco de proyectos
          elegibles enfocados a la investigación marino-costera, que genere
          herramientas para la gestión y el manejo de los territorios.
        </p>
        {/* <button className="button">Formulario de postulación ECS 2022</button> */}
      </div>
    </div>
  </section>
);
