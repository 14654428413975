import React from "react";
import pacifico from "./pacifico.jpeg";
import seaflower from "./seaflower.jpeg";
import "./convocatorias.css";

export default () => (
  <section className="convocatorias">
    <div
      className="seaflower"
      style={{
        backgroundColor: "#F4F4F4",
        paddingTop: "30px",
        paddingBottom: "30px",
      }}
    >
      <div className="firstDiv">
        <img src={pacifico}></img>
      </div>
      <div className="secondDiv">
        <h4>Expedición Científica Pacífico 2023 - Golfo de Tribugá </h4>
          <p
            className="parrafo"
          >
          La Expedición Científica Pacífico 2023 Golfo de Tribugá - Cabo Corrientes tiene como objetivo constituir un equipo científico de alto nivel, que aporte directamente en la consolidación de la primera Reserva de Biosfera del Pacífico colombiano como instrumento de manejo integral del territorio. Para esto, los proyectos aprobados estarán enfocados en las líneas del ´Programa de Investigación´ del Plan de Gestión de la Reserva de Biosfera Tribugá - Cupica - Baudó.  Teniendo en cuenta que los programas de este plan están articulados entre sí, es fundamental que los proyectos generen insumos que contribuyan con el desarrollo de las líneas de los otros programas (i.e. producción sostenible, conservación, educación y gobernanza).
          <br />
          <br />
          De está manera, la Mesa Interinstitucional Técnica (MIT) del Programa Pacífico - PNEC lanza la convocatoria para la consolidación de un banco de proyectos de investigación elegibles, para el desarrollo de la fase de campo durante el primer semestre del año 2023.
          </p>
          <a
          className="button"
          href="https://forms.gle/Stiyh7utcPb6u8779"
        >
          Formulario de postulación ECP 2023
        </a>
      </div>
    </div>
    <div className="seaflower" style={{ marginBottom: "30px", 
        paddingTop: "30px",
      }}>
      <div className="firstDiv">
        <img src={seaflower}></img>
      </div>
      <div className="secondDiv">
        <h4>Expedición Científica Seaflower 2022 - Isla Cayos de Bolívar</h4>
        <p className="parrafo">
          La Expedición Científica Seaflower se desarrolla anualmente, desde
          2014, en el Archipiélago de San Andrés, Providencia y Santa Catalina,
          el cual fue declarado en el año 2000 como Reserva de Biosfera
          Seaflower por la UNESCO, bajo el Programa de “El Hombre y la
          Biosfera”, figura de manejo enfocada al desarrollo sostenible de los
          territorios.
          <br />
          <br />
          Este año el escenario de la Expedición Científica Seaflower será la
          Isla Cayos de Bolívar (Este Sureste), un atolón localizado a 18 millas
          náuticas al sureste de San Andrés. El atolón tiene forma ovalada
          irregular, una longitud máxima en dirección SSE-NNW de 6.4km y un
          ancho de 3.5 KM. El atolón posee dos cayos: East Cay, el cual tiene
          una vegetación de palmas de coco, arbustos bajos y algunas gramíneas,
          es utilizado por los pescadores para pernoctar durante sus jornadas de
          pesca. Por su parte, West Cay tiene actualmente un faro como ayuda a
          la navegación, y el puesto militar de la Armada Nacional para control
          y vigilancia en la Reserva de Biosfera.
        </p>
        <a
          className="button"
          // href="https://docs.google.com/forms/d/e/1FAIpQLSfsWdGWY0mNkKw34iBoak6YZKpOzQTH62vA33Nq8ZFb-0h_GA/viewform?vc=0&c=0&w=1&flr=0"
        >
          Convocatoria Cerrada
        </a>
      </div>
    </div>
  </section>
);
