import vp from "./VP.jpeg";
import cco from "./CCO.jpeg";
import dimar from "./dimar.jpeg";
import arc from "./ARC.jpeg";
import minciencias from "./MINCIENCIAS.jpeg";
import colbio from "./COLBIO.jpeg";
import ceramin from "./CEMARIN.jpeg";
import rapacifico from "./RAPACIFICO.jpeg";
import codechoco from "./CODECHOCO.jpeg";
import IIAP_2 from "./IIAP_2.png";
import logoCoralina from "./logo-coralina-mejor.jpeg";
import gobArchipielago from "./gobArchipielago.jpeg";
import minambiente from "./Minambiente.png";
import pnecA from "./PNEC-A.png";
import parquesN from "./parquesN.jpeg";
import INVEMAR from './INVEMAR.png';
import igac from './igac.png';
import AUNAP from './AUNAP.png';
import fb from "./fb.png";
import instagram from "./instagram.png";
import twitter from "./twitter.png";
import youtube from "./youtube.png";
import "./App.css";
import {
  Banner,
  Convocatorias,
  Cronograma,
  DocumentosPostulacion,
  Introduccion,
  Mapa,
} from "./sections";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

function App() {
  const Home = () => {
    return (
      <>
        <header className="App-header">
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <img className="pnecImg" src={pnecA} />
          </div>
          <div>
            <HashLink smooth to="/#introduccion">
              PNEC
            </HashLink>{" "}
            |{" "}
            <HashLink smooth to="/#seaflower">
              Pacífico{" "}
            </HashLink>
            {/* |{" "}
            <HashLink smooth to="/#seaflower">
              Pacífico
            </HashLink>{" "} */}
            |
            <HashLink smooth to="/#postulacion">
              {" "}
              Documentos de postulación{" "}
            </HashLink>
            |{" "}
            <HashLink smooth to="/#cronograma">
              Cronograma
            </HashLink>
          </div>
          <div className="social-networks">
            <a href="https://es-es.facebook.com/comision.deloceano">
              <img src={fb} alt="facebook" />
            </a>

            <a href="https://instagram.com/ccoceano?igshid=YmMyMTA2M2Y= ">
              <img src={instagram} alt="instagram" />
            </a>

            <a href="https://twitter.com/search/?q=@ccoceano">
              <img src={twitter} alt="twitter" />
            </a>

            <a href="https://www.youtube.com/user/ComisionCCO">
              <img src={youtube} alt="youtube" />
            </a>
          </div>
        </header>
        <Banner />
        <div id="introduccion">
          <Introduccion />
        </div>
        <div id="seaflower">
          <Convocatorias />
        </div>
        <div id="postulacion">
          <DocumentosPostulacion />
        </div>
        <Mapa />
        <div id="cronograma">
          <Cronograma />
        </div>

        <footer>
          <img src={vp} />
          <img src={cco} />
          <img src={arc} />
          <img src={dimar} />
          <img src={minciencias} />
          <img src={colbio} />
          <img src={minambiente} />
          <img src={parquesN} />
          <img src={INVEMAR} />
          <img src={igac} style={{marginLeft: '10px'}}/>
          <img src={AUNAP} />
          <img src={gobArchipielago} />
          <img src={logoCoralina} />
          <img src={rapacifico} />
          <img src={codechoco} />
          <img src={IIAP_2} />
          <img src={ceramin} />
        </footer>
      </>
    );
  };
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />}></Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
