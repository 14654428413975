/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import descarga from "./descarga.png";
import "./documentosPostulacion.css";
import background from "./document_background.jpeg";
// import TERMINOSREFERENCIAPNEC from "./TERMINOSREFERENCIAPNEC.pdf";
export default () => (
  <div
    className="documents"
    style={{
      backgroundImage: `url(${background})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    }}
  >
    <div className="part1">
      <p>Documentos de Postulación</p>
    </div>
    <div className="part2">
      <a href="/TERMINOSREFERENCIAPNEC.pdf" download>
        <img
          src={descarga}
          style={{ height: "70px", paddingRight: "5%" }}
          alt="descarga"
        />
        <div className="file">
          <p>Términos de referencia</p>
        </div>
      </a>

      <a href="/Formato_de_proyectos_PNEC.zip" download>
        <img
          src={descarga}
          style={{ height: "70px", paddingRight: "5%" }}
          alt="descarga"
        />
        <div className="file">
          <p>Formato de proyecto</p>
        </div>
      </a>

      <a href="/FORMATO_LOGISTICO_ADMINISTRATIVO_PNEC.docx" download>
        <img
          src={descarga}
          style={{ height: "70px", paddingRight: "5%" }}
          alt="descarga"
        />
        <div>
          <p>Formato logístico</p>
        </div>
      </a>
    </div>
  </div>
);
