/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import calendario from "./calendario.png";

export default () => (
  <section className="cronograma">
    <div style={{ display: "flex", alignItems: "center" }}>
      <p className="title">CRONOGRAMA</p>
      <img src={calendario} style={{ height: "50px", paddingLeft: "10px" }} />
    </div>
    <div className="dates">
      <div>
        <p>Evaluación del Comité Científico Seaflower</p>
      </div>
      <div>
        <p>08 de julio - 26 de julio de 2022</p>
      </div>
    </div>
    <div className="dates">
      <div>
        <p>Comentarios y subsanaciones de proyectos Seaflower</p>
      </div>
      <div>
        <p>27 de julio - 29 de julio de 2022</p>
      </div>
    </div>
    <div className="dates">
      <div>
        <p>Publicación de proyectos seleccionados Seaflower</p>
      </div>
      <div>
        <p>1 de agosto de 2022</p>
      </div>
    </div>
    <div className="dates">
      <div>
        <p>Convocatoria de proyectos de investigación ECP</p>
      </div>
      <div>
        <p>18 de julio - 9 de septiembre de 2022</p>
      </div>
    </div>
    <div className="dates">
      <div>
        <p>Evaluación del Comité Científico Pacífico</p>
      </div>
      <div>
        <p>12 de septiembre - 26 de septiembre de 2022</p>
      </div>
    </div>
    <div className="dates">
      <div>
        <p>Aprobación de proyectos por las comunidades del Pacífico</p>
      </div>
      <div>
        <p>30 de septiembre - 2 de octubre de 2022</p>
      </div>
    </div>
    <div className="dates">
      <div>
        <p>Comentarios y subsanaciones de proyectos Pacífico</p>
      </div>
      <div>
        <p>5 de octubre - 12 de octubre de 2022</p>
      </div>
    </div>
    <div className="dates">
      <div>
        <p>Publicación de proyectos seleccionados Pacífico</p>
      </div>
      <div>
        <p>17 de octubre de 2022 </p>
      </div>
    </div>
  </section>
);
